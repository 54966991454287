@import './layout/variables';

.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-track-color: var(--secondaryTextColor);
  --mdc-switch-selected-hover-track-color: var(--secondaryTextColor);
  --mdc-switch-selected-pressed-track-color: var(--secondaryTextColor);
  --mdc-switch-selected-track-color: var(--secondaryTextColor);
  --mdc-switch-selected-focus-state-layer-color: var(
    --navigationTextActiveColor
  );
  --mdc-switch-selected-handle-color: var(--navigationTextActiveColor);
  --mdc-switch-selected-hover-state-layer-color: var(
    --navigationTextActiveColor
  );
  --mdc-switch-selected-pressed-state-layer-color: var(
    --navigationTextActiveColor
  );
  --mdc-switch-selected-focus-handle-color: var(--navigationTextActiveColor);
  --mdc-switch-selected-hover-handle-color: var(--secondaryTextColor);
  --mdc-switch-selected-pressed-handle-color: var(--navigationTextActiveColor);
}
.mdc-switch:enabled .mdc-switch__track::after {
  background: var(--secondaryTextColor) !important;
  opacity: 0.3;
}
