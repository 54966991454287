.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.p-5 {
  padding: 3rem;
}

.px-0 {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.px-1 {
  padding-inline-end: 0.25rem;
  padding-inline-start: 0.25rem;
}

.px-2 {
  padding-inline-end: 0.5rem;
  padding-inline-start: 0.5rem;
}

.px-3 {
  padding-inline-end: 1rem;
  padding-inline-start: 1rem;
}

.px-4 {
  padding-inline-end: 1.5rem;
  padding-inline-start: 1.5rem;
}

.px-5 {
  padding-inline-end: 3rem;
  padding-inline-start: 3rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pe-0 {
  padding-inline-end: 0;
}

.pe-1 {
  padding-inline-end: 0.25rem;
}

.pe-2 {
  padding-inline-end: 0.5rem;
}

.pe-3 {
  padding-inline-end: 1rem;
}

.pe-4 {
  padding-inline-end: 1.5rem;
}

.pe-5 {
  padding-inline-end: 3rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.ps-0 {
  padding-inline-start: 0;
}

.ps-1 {
  padding-inline-start: 0.25rem;
}

.ps-2 {
  padding-inline-start: 0.5rem;
}

.ps-3 {
  padding-inline-start: 1rem;
}

.ps-4 {
  padding-inline-start: 1.5rem;
}

.ps-5 {
  padding-inline-start: 3rem;
}

@include media-breakpoint-min('sm') {
  .p-sm-0 {
    padding: 0;
  }

  .p-sm-1 {
    padding: 0.25rem;
  }

  .p-sm-2 {
    padding: 0.5rem;
  }

  .p-sm-3 {
    padding: 1rem;
  }

  .p-sm-4 {
    padding: 1.5rem;
  }

  .p-sm-5 {
    padding: 3rem;
  }

  .px-sm-0 {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .px-sm-1 {
    padding-inline-end: 0.25rem;
    padding-inline-start: 0.25rem;
  }

  .px-sm-2 {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .px-sm-3 {
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  .px-sm-4 {
    padding-inline-end: 1.5rem;
    padding-inline-start: 1.5rem;
  }

  .px-sm-5 {
    padding-inline-end: 3rem;
    padding-inline-start: 3rem;
  }

  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-sm-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-sm-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .py-sm-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-sm-0 {
    padding-top: 0;
  }

  .pt-sm-1 {
    padding-top: 0.25rem;
  }

  .pt-sm-2 {
    padding-top: 0.5rem;
  }

  .pt-sm-3 {
    padding-top: 1rem;
  }

  .pt-sm-4 {
    padding-top: 1.5rem;
  }

  .pt-sm-5 {
    padding-top: 3rem;
  }

  .pe-sm-0 {
    padding-inline-end: 0;
  }

  .pe-sm-1 {
    padding-inline-end: 0.25rem;
  }

  .pe-sm-2 {
    padding-inline-end: 0.5rem;
  }

  .pe-sm-3 {
    padding-inline-end: 1rem;
  }

  .pe-sm-4 {
    padding-inline-end: 1.5rem;
  }

  .pe-sm-5 {
    padding-inline-end: 3rem;
  }

  .pb-sm-0 {
    padding-bottom: 0;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem;
  }

  .pb-sm-3 {
    padding-bottom: 1rem;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem;
  }

  .pb-sm-5 {
    padding-bottom: 3rem;
  }

  .ps-sm-0 {
    padding-inline-start: 0;
  }

  .ps-sm-1 {
    padding-inline-start: 0.25rem;
  }

  .ps-sm-2 {
    padding-inline-start: 0.5rem;
  }

  .ps-sm-3 {
    padding-inline-start: 1rem;
  }

  .ps-sm-4 {
    padding-inline-start: 1.5rem;
  }

  .ps-sm-5 {
    padding-inline-start: 3rem;
  }
}

@include media-breakpoint-min('md') {
  .p-md-0 {
    padding: 0;
  }

  .p-md-1 {
    padding: 0.25rem;
  }

  .p-md-2 {
    padding: 0.5rem;
  }

  .p-md-3 {
    padding: 1rem;
  }

  .p-md-4 {
    padding: 1.5rem;
  }

  .p-md-5 {
    padding: 3rem;
  }

  .px-md-0 {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .px-md-1 {
    padding-inline-end: 0.25rem;
    padding-inline-start: 0.25rem;
  }

  .px-md-2 {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .px-md-3 {
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  .px-md-4 {
    padding-inline-end: 1.5rem;
    padding-inline-start: 1.5rem;
  }

  .px-md-5 {
    padding-inline-end: 3rem;
    padding-inline-start: 3rem;
  }

  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-md-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-md-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .py-md-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-md-0 {
    padding-top: 0;
  }

  .pt-md-1 {
    padding-top: 0.25rem;
  }

  .pt-md-2 {
    padding-top: 0.5rem;
  }

  .pt-md-3 {
    padding-top: 1rem;
  }

  .pt-md-4 {
    padding-top: 1.5rem;
  }

  .pt-md-5 {
    padding-top: 3rem;
  }

  .pe-md-0 {
    padding-inline-end: 0;
  }

  .pe-md-1 {
    padding-inline-end: 0.25rem;
  }

  .pe-md-2 {
    padding-inline-end: 0.5rem;
  }

  .pe-md-3 {
    padding-inline-end: 1rem;
  }

  .pe-md-4 {
    padding-inline-end: 1.5rem;
  }

  .pe-md-5 {
    padding-inline-end: 3rem;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem;
  }

  .pb-md-3 {
    padding-bottom: 1rem;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem;
  }

  .pb-md-5 {
    padding-bottom: 3rem;
  }

  .ps-md-0 {
    padding-inline-start: 0;
  }

  .ps-md-1 {
    padding-inline-start: 0.25rem;
  }

  .ps-md-2 {
    padding-inline-start: 0.5rem;
  }

  .ps-md-3 {
    padding-inline-start: 1rem;
  }

  .ps-md-4 {
    padding-inline-start: 1.5rem;
  }

  .ps-md-5 {
    padding-inline-start: 3rem;
  }
}

@include media-breakpoint-min('lg') {
  .p-lg-0 {
    padding: 0;
  }

  .p-lg-1 {
    padding: 0.25rem;
  }

  .p-lg-2 {
    padding: 0.5rem;
  }

  .p-lg-3 {
    padding: 1rem;
  }

  .p-lg-4 {
    padding: 1.5rem;
  }

  .p-lg-5 {
    padding: 3rem;
  }

  .px-lg-0 {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .px-lg-1 {
    padding-inline-end: 0.25rem;
    padding-inline-start: 0.25rem;
  }

  .px-lg-2 {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .px-lg-3 {
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  .px-lg-4 {
    padding-inline-end: 1.5rem;
    padding-inline-start: 1.5rem;
  }

  .px-lg-5 {
    padding-inline-end: 3rem;
    padding-inline-start: 3rem;
  }

  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-lg-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-lg-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .py-lg-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-lg-0 {
    padding-top: 0;
  }

  .pt-lg-1 {
    padding-top: 0.25rem;
  }

  .pt-lg-2 {
    padding-top: 0.5rem;
  }

  .pt-lg-3 {
    padding-top: 1rem;
  }

  .pt-lg-4 {
    padding-top: 1.5rem;
  }

  .pt-lg-5 {
    padding-top: 3rem;
  }

  .pe-lg-0 {
    padding-inline-end: 0;
  }

  .pe-lg-1 {
    padding-inline-end: 0.25rem;
  }

  .pe-lg-2 {
    padding-inline-end: 0.5rem;
  }

  .pe-lg-3 {
    padding-inline-end: 1rem;
  }

  .pe-lg-4 {
    padding-inline-end: 1.5rem;
  }

  .pe-lg-5 {
    padding-inline-end: 3rem;
  }

  .pb-lg-0 {
    padding-bottom: 0;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem;
  }

  .pb-lg-3 {
    padding-bottom: 1rem;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem;
  }

  .pb-lg-5 {
    padding-bottom: 3rem;
  }

  .ps-lg-0 {
    padding-inline-start: 0;
  }

  .ps-lg-1 {
    padding-inline-start: 0.25rem;
  }

  .ps-lg-2 {
    padding-inline-start: 0.5rem;
  }

  .ps-lg-3 {
    padding-inline-start: 1rem;
  }

  .ps-lg-4 {
    padding-inline-start: 1.5rem;
  }

  .ps-lg-5 {
    padding-inline-start: 3rem;
  }
}

@include media-breakpoint-min('xl') {
  .p-xl-0 {
    padding: 0;
  }

  .p-xl-1 {
    padding: 0.25rem;
  }

  .p-xl-2 {
    padding: 0.5rem;
  }

  .p-xl-3 {
    padding: 1rem;
  }

  .p-xl-4 {
    padding: 1.5rem;
  }

  .p-xl-5 {
    padding: 3rem;
  }

  .px-xl-0 {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .px-xl-1 {
    padding-inline-end: 0.25rem;
    padding-inline-start: 0.25rem;
  }

  .px-xl-2 {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .px-xl-3 {
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  .px-xl-4 {
    padding-inline-end: 1.5rem;
    padding-inline-start: 1.5rem;
  }

  .px-xl-5 {
    padding-inline-end: 3rem;
    padding-inline-start: 3rem;
  }

  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-xl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-xl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .py-xl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-xl-0 {
    padding-top: 0;
  }

  .pt-xl-1 {
    padding-top: 0.25rem;
  }

  .pt-xl-2 {
    padding-top: 0.5rem;
  }

  .pt-xl-3 {
    padding-top: 1rem;
  }

  .pt-xl-4 {
    padding-top: 1.5rem;
  }

  .pt-xl-5 {
    padding-top: 3rem;
  }

  .pe-xl-0 {
    padding-inline-end: 0;
  }

  .pe-xl-1 {
    padding-inline-end: 0.25rem;
  }

  .pe-xl-2 {
    padding-inline-end: 0.5rem;
  }

  .pe-xl-3 {
    padding-inline-end: 1rem;
  }

  .pe-xl-4 {
    padding-inline-end: 1.5rem;
  }

  .pe-xl-5 {
    padding-inline-end: 3rem;
  }

  .pb-xl-0 {
    padding-bottom: 0;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem;
  }

  .pb-xl-3 {
    padding-bottom: 1rem;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem;
  }

  .pb-xl-5 {
    padding-bottom: 3rem;
  }

  .ps-xl-0 {
    padding-inline-start: 0;
  }

  .ps-xl-1 {
    padding-inline-start: 0.25rem;
  }

  .ps-xl-2 {
    padding-inline-start: 0.5rem;
  }

  .ps-xl-3 {
    padding-inline-start: 1rem;
  }

  .ps-xl-4 {
    padding-inline-start: 1.5rem;
  }

  .ps-xl-5 {
    padding-inline-start: 3rem;
  }
}

@include media-breakpoint-min('xxl') {
  .p-xxl-0 {
    padding: 0;
  }

  .p-xxl-1 {
    padding: 0.25rem;
  }

  .p-xxl-2 {
    padding: 0.5rem;
  }

  .p-xxl-3 {
    padding: 1rem;
  }

  .p-xxl-4 {
    padding: 1.5rem;
  }

  .p-xxl-5 {
    padding: 3rem;
  }

  .px-xxl-0 {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  .px-xxl-1 {
    padding-inline-end: 0.25rem;
    padding-inline-start: 0.25rem;
  }

  .px-xxl-2 {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .px-xxl-3 {
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  .px-xxl-4 {
    padding-inline-end: 1.5rem;
    padding-inline-start: 1.5rem;
  }

  .px-xxl-5 {
    padding-inline-end: 3rem;
    padding-inline-start: 3rem;
  }

  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .py-xxl-3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .py-xxl-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .py-xxl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .pt-xxl-0 {
    padding-top: 0;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem;
  }

  .pt-xxl-3 {
    padding-top: 1rem;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem;
  }

  .pt-xxl-5 {
    padding-top: 3rem;
  }

  .pe-xxl-0 {
    padding-inline-end: 0;
  }

  .pe-xxl-1 {
    padding-inline-end: 0.25rem;
  }

  .pe-xxl-2 {
    padding-inline-end: 0.5rem;
  }

  .pe-xxl-3 {
    padding-inline-end: 1rem;
  }

  .pe-xxl-4 {
    padding-inline-end: 1.5rem;
  }

  .pe-xxl-5 {
    padding-inline-end: 3rem;
  }

  .pb-xxl-0 {
    padding-bottom: 0;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem;
  }

  .ps-xxl-0 {
    padding-inline-start: 0;
  }

  .ps-xxl-1 {
    padding-inline-start: 0.25rem;
  }

  .ps-xxl-2 {
    padding-inline-start: 0.5rem;
  }

  .ps-xxl-3 {
    padding-inline-start: 1rem;
  }

  .ps-xxl-4 {
    padding-inline-start: 1.5rem;
  }

  .ps-xxl-5 {
    padding-inline-start: 3rem;
  }
}
