$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Mixin for min-width breakpoint
@mixin media-breakpoint-min($breakpoint) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint)) {
    @content;
  }
}

// Mixin for max-width breakpoint
@mixin media-breakpoint-max($breakpoint) {
  @media (max-width: map-get($grid-breakpoints, $breakpoint) - 1) {
    @content;
  }
}

// Mixin for range between min-width and max-width breakpoints
@mixin media-breakpoint-between($breakpoint-min, $breakpoint-max) {
  @media (min-width: map-get($grid-breakpoints, $breakpoint-min)) and (max-width: map-get($grid-breakpoints, $breakpoint-max) - 1) {
    @content;
  }
}
