@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-RegularItalic.woff2') format('woff2'),
    url('Fellix-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-ExtraBoldItalic.woff2') format('woff2'),
    url('Fellix-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-MediumItalic.woff2') format('woff2'),
    url('Fellix-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Bold.woff2') format('woff2'),
    url('Fellix-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Regular.woff2') format('woff2'),
    url('Fellix-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Black.woff2') format('woff2'),
    url('Fellix-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-ThinItalic.woff2') format('woff2'),
    url('Fellix-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-BlackItalic.woff2') format('woff2'),
    url('Fellix-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Medium.woff2') format('woff2'),
    url('Fellix-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Light.woff2') format('woff2'),
    url('Fellix-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-SemiBold.woff2') format('woff2'),
    url('Fellix-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-SemiBoldItalic.woff2') format('woff2'),
    url('Fellix-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Regular.woff2') format('woff2'),
    url('Fellix-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Thin.woff2') format('woff2'),
    url('Fellix-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-BoldItalic.woff2') format('woff2'),
    url('Fellix-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-LightItalic.woff2') format('woff2'),
    url('Fellix-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-BoldItalic.woff2') format('woff2'),
    url('Fellix-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-LightItalic.woff2') format('woff2'),
    url('Fellix-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-Medium.woff2') format('woff2'),
    url('Fellix-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fellix';
  src:
    url('Fellix-ExtraBold.woff2') format('woff2'),
    url('Fellix-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
